import React from "react"
import styled from "styled-components"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

const InsuranceLogos = props => (
  <Container>
    <AliceCarousel
      mouseDragEnabled
      autoPlay
      responsive={responsive}
      autoPlayInterval={2000}
      duration={500}
      fadeOutAnimation={true}
      swipeDisabled
      infinite
      playButtonEnabled={false}
      showSlideInfo={false}
      buttonsDisabled
      dotsDisabled
    >
      {logos.map((logo, index) => (
        <LogoContainer key={index}>
          <Logo src={logo.image} alt={logo.title} />
        </LogoContainer>
      ))}
    </AliceCarousel>
  </Container>
)

export default InsuranceLogos

const responsive = {
  0: {
    items: 2,
  },
  350: {
    items: 3,
  },
  600: {
    items: 4,
  },
  1024: {
    items: 6,
  },
}

const Container = styled.div`
  background: none;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  position: relative;
  width: 100%;
  margin: 0 auto;
`
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 0 20px;
  width: 80px;
`
const Logo = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  align-self: center;
`

const logos = [
  {
    title: "Tatil",
    image: require("../images/insurance/tatil-logo.png"),
  },
  {
    title: "Guardian",
    image: require("../images/insurance/logo.png"),
  },
  {
    title: "Sagicor",
    image: require("../images/insurance/sagicor.png"),
  },
  {
    title: "Beacon",
    image: require("../images/insurance/beacon.png"),
  },
  {
    title: "Clico",
    image: require("../images/insurance/clico.png"),
  },
  {
    title: "Maritime",
    image: require("../images/insurance/CROPPEDMaritime1.png"),
  },
  {
    title: "Paho",
    image: require("../images/insurance/paho.png"),
  },
  {
    title: "Cardia",
    image: require("../images/insurance/cardia.jpg"),
  },
]
