import React from "react"
import styled from "styled-components"
import { SectionTitle } from "./Section-Title"
import { SectionSubtitle } from "./Section-Subtitle"
import MainButton from "./main-button"
import { Colors } from "../colors/Colors"
import { navigate } from "gatsby"
import InsuranceLogos from "./insurance-logos"

const Container = styled.div`
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
  text-align: center;
`
const Copy = styled.p`
  margin: 0;
  max-width: 450px;
  font-family: "Articulat";
  font-weight: 100;
  color: #151515;
  font-size: 15px;
  margin-bottom: 20px;
`
const NewPatient = props => (
  <Container>
    <SectionTitle>Anxious about your first visit?</SectionTitle>
    <Copy>
      We understand that you, your child or your loved one, may feel uneasy
      about their visit to the dentist. At Smile Inn, we've made it remarkably
      easy to get started. We accept most insurance plans and offer a variety of
      payment plans.
    </Copy>
    <SectionSubtitle style={{ marginTop: "30px" }}>
      Our Accepted Insurance Plans
    </SectionSubtitle>
    <InsuranceLogos />
  </Container>
)

export default NewPatient
