import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import { Colors } from "../colors/Colors"

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
  cursor: pointer;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`
const Icon = styled.img`
  width: 80px;
  margin: 0;
  @media (max-width: 500px) {
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    width: 50px;
  }
`
const CopyContainer = styled.div`
  font-family: "Articulat";
  margin: 0 0 0 50px;
  transition: 0.3s ease-in-out;

  @media (max-width: 500px) {
    margin: 0;
  }

  ${Container}:hover & {
    opacity: 0.7;
  }
`
const Title = styled.h2`
  font-family: "Articulat";
  text-transform: uppercase;
  font-size: 20px;
  color: #2b2b2b;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    text-align: center;
  }
`
const Copy = styled.div`
  margin-bottom: 10px;

  p {
    font-size: 15px;
    color: #333333;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`
const Linkto = styled.div`
  margin: 0;
  color: ${Colors.gold};
  font-family: "Articulat";
  font-weight: 700;
  font-size: 14px;
  transition: 0.3s ease-in-out;

  ${Container}:hover & {
    transform: translateX(5px);
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`

const OurServiceItem = props => (
  <Container onClick={() => navigate(`${props.link}`)}>
    {!props.noIcon && <Icon src={props.icon} />}

    <CopyContainer>
      <Title dangerouslySetInnerHTML={{ __html: props.title }} />
      <Copy dangerouslySetInnerHTML={{ __html: props.copy }} />
      <Linkto>
        <Link style={{ color: "inherit" }} to={`${props.link}`}>
          Explore Services
        </Link>
      </Linkto>
    </CopyContainer>
  </Container>
)

export default OurServiceItem
